import React from "react";

import { Strain } from "custom-types/Strain";
import { useEventTracker } from "hooks/useEventTracker";

import Button from "components/botanic/Button";
import TrackImpression from "components/TrackImpression";

const ShopStrainButton: React.FC<{
  strain: Strain;
}> = ({ strain }) => {
  const { publishEvent } = useEventTracker();
  const strainId = strain.id;

  return (
    <TrackImpression
      className="pt-3"
      trackingFunction={() => {
        return publishEvent({
          action: "impression",
          category: "button",
          label: "shop strain",
          strainId,
        });
      }}
    >
      <Button
        href="#strain-shopping"
        data-testid="strain-shop-button"
        onClick={() => {
          publishEvent({
            action: "click",
            category: "button",
            label: "shop strain",
            strainId,
          });
        }}
      >
        shop {strain.name}
      </Button>
    </TrackImpression>
  );
};

export default ShopStrainButton;
